// vendors
import React from "react"
import { css } from "@emotion/core"

// components
import Layout from "../components/Layout"
import Seo from "../components/Seo"

// utils
import wrapper from "../utils/wrapper"
import mediaQuery from "../utils/media-query"
import { fontSizes } from "../styles/variables"

const PoliciesPage = () => {
  const breakpoint = 1024
  return (
    <Layout>
      <Seo
        description="Notre politique de confidentialité vous explique les différentes collectes et utilisations de vos données personnelles et des protections qui sont mises en place."
        lang="fr"
        keywords={[]}
        title="Notre politique de confidentialité"
      />

      <article
        css={css`
          ${wrapper.bolt()}
          margin-bottom: ${fontSizes[9] / fontSizes[0]}em;

          ${mediaQuery.greaterThen(breakpoint)} {
            ${wrapper.paddingLeft()}
            ${wrapper.paddingRight()}
          }
        `}
      >
        <h1>
          Politique de <br />
          confidentialité
        </h1>

        <p>
          La protection de vos renseignements personnels est importante pour
          SARROS. C&rsquo;est pourquoi nous faisons notre possible pour
          prot&eacute;ger les informations que vous nous fournissez sur
          certaines pages de notre site Web ou dans un quiz. La pr&eacute;sente
          politique de confidentialit&eacute; vous aidera &agrave; comprendre
          comment SARROS recueille, utilise et divulgue vos renseignements
          personnels.
        </p>
        <p>
          En acc&eacute;dant au site Web de SARROS ainsi qu&rsquo;aux
          diff&eacute;rents quiz, vous consentez, dans les limites couvertes par
          la pr&eacute;sente politique, &agrave; la collecte et &agrave;
          l&rsquo;utilisation de vos renseignements par SARROS.
        </p>
        <p>
          Puisque les modalit&eacute;s de cette convention peuvent &ecirc;tre
          modifi&eacute;es, &agrave; la discr&eacute;tion exclusive de SARROS,
          sans que vous en soyez avis&eacute;, nous vous conseillons de visiter
          cette page Web de temps &agrave; autre afin de vous tenir au courant
          des changements. Votre utilisation continue de cette page Web
          attestera votre consentement &agrave; tout changement.
        </p>

        <h2 className="h3">Collecte des renseignements</h2>
        <p>
          Nous recueillons vos renseignements personnels de plusieurs
          fa&ccedil;ons, entre autres, lorsque vous saisissez des renseignements
          sur notre site Web (notamment avec l&rsquo;utilisation des formulaires
          de demandes d&rsquo;information), lorsque vous vous abonnez &agrave;
          notre infolettre, lorsque vous communiquez avec nous par
          t&eacute;l&eacute;phone ou par courriel, ou lorsque vous publiez du
          contenu sur nos comptes de m&eacute;dias sociaux.
        </p>
        <p>
          Selon les circonstances, les renseignements personnels peuvent
          comprendre nom, pr&eacute;nom, adresse postale, code postal, adresse
          courriel, num&eacute;ro de t&eacute;l&eacute;phone, sexe, date de
          naissance, scolarit&eacute;, profession et, si vous l&rsquo;acceptez,
          certaines autres informations. SARROS recueille uniquement les
          renseignements dont nous avons besoin pour pouvoir vous offrir nos
          services.
        </p>
        <p>
          Nous recueillons &eacute;galement des donn&eacute;es lorsque vous
          visitez notre site Web, notamment votre adresse IP, la date,
          l&rsquo;heure et la dur&eacute;e de vos visites ainsi que les pages
          que vous avez consult&eacute;es. Comme la plupart des sites Web, nous
          utilisons des t&eacute;moins de connexion. Il s&rsquo;agit de fichiers
          qui sont install&eacute;s sur le disque dur de votre ordinateur ou sur
          votre navigateur Web pour recueillir des renseignements, comme votre
          langue de pr&eacute;f&eacute;rence, votre historique de navigation
          avec nous ainsi que le type et la version de votre navigateur, et dont
          l&rsquo;objectif est d&rsquo;optimiser votre exp&eacute;rience sur
          notre site Web. Nous utilisons des t&eacute;moins pour compiler des
          donn&eacute;es agr&eacute;g&eacute;es sur le trafic du site et sur les
          interactions avec celui-ci en vue d&rsquo;am&eacute;liorer
          l&rsquo;exp&eacute;rience offerte et les outils qui s&rsquo;y
          trouvent.
        </p>
        <p>
          Nous utilisons &eacute;galement d&rsquo;autres technologies comme les
          pixels, pour faire le suivi de votre utilisation de notre site Web, et
          nos communications diverses. Des soci&eacute;t&eacute;s
          affili&eacute;es, comme nos fournisseurs, sont autoris&eacute;es
          &agrave; le faire en notre nom (incluant, sans pr&eacute;tendre
          &agrave; l&rsquo;exhaustivit&eacute; et modifiable sans
          pr&eacute;avis, Google Analytics, Facebook, Mautic). Les pixels sont
          de minuscules images graphiques int&eacute;gr&eacute;es dans un
          certain nombre de pages de notre site Web, dans nos courriels, ou dans
          diff&eacute;rentes campagnes de communication qui nous permettent de
          d&eacute;terminer si vous avez ou non fait un geste particulier.
          Ainsi, nous pouvons mesurer et am&eacute;liorer notre fa&ccedil;on
          d&rsquo;&eacute;valuer la pr&eacute;sence, le comportement des
          visiteurs sur notre site Web, de m&ecirc;me que
          l&rsquo;efficacit&eacute; de nos communications.
        </p>

        <h2 className="h3">Utilisation des renseignements</h2>
        <p>
          Nous recueillons vos renseignements personnels pour que seule notre
          &eacute;quipe SARROS puisse les consulter dans le cadre de ses
          fonctions et mandats. Nous utilisons et communiquons vos
          renseignements personnels principalement&nbsp;:
        </p>
        <ul>
          <li>
            pour exploiter, maintenir, am&eacute;liorer et offrir toutes les
            fonctionnalit&eacute;s du site Web, fournir les services et
            l&rsquo;information que vous demandez, r&eacute;pondre aux
            commentaires et aux questions, et fournir du soutien aux
            utilisateurs du site Web;
          </li>
          <li>
            pour comprendre et analyser les tendances d&rsquo;utilisation et les
            pr&eacute;f&eacute;rences de nos utilisateurs, am&eacute;liorer le
            site Web, et cr&eacute;er de nouveaux services et de nouvelles
            caract&eacute;ristiques et fonctionnalit&eacute;s;
          </li>
          <li>
            pour vous transmettre des communications conform&eacute;ment
            &agrave; la loi;
          </li>
          <li>&agrave; toute autre fin permise ou requise par la loi.</li>
        </ul>
        <p>
          Lorsque nous communiquons vos renseignements personnels &agrave; des
          tiers, nous prenons des mesures raisonnables pour que les
          r&egrave;gles &eacute;nonc&eacute;es dans la pr&eacute;sente politique
          soient respect&eacute;es, et ces tiers nous fournissent des garanties
          suffisantes quant &agrave; la mise en &oelig;uvre des mesures
          techniques et organisationnelles appropri&eacute;es. SARROS ne vend sa
          liste d&rsquo;abonn&eacute;s ou de clients &agrave; aucun organisme ou
          compagnie ni ne l&rsquo;&eacute;change avec quiconque.
        </p>
        <p>
          Enfin, nous pouvons mettre &agrave; la disposition de tiers certains
          renseignements recueillis automatiquement, notamment &agrave; des fins
          de conformit&eacute; &agrave; diverses obligations de
          d&eacute;claration, &agrave; des fins de marketing et pour aider ces
          tiers &agrave; comprendre vos int&eacute;r&ecirc;ts, vos
          pr&eacute;f&eacute;rences et vos habitudes d&rsquo;utilisation
          relativement &agrave; certains programmes, contenus ou services ou
          &agrave; certaines fonctionnalit&eacute;s du site Web.
        </p>

        <h2 className="h3">Conservation des renseignements</h2>
        <p>
          Vos renseignements personnels peuvent &ecirc;tre stock&eacute;s et
          trait&eacute;s uniquement au Qu&eacute;bec, dans toutes les
          r&eacute;gions o&ugrave; nous avons un repr&eacute;sentant. En
          utilisant le site Web et ses applications, vous consentez au transfert
          de renseignements. Nos pratiques concernant vos renseignements
          personnels demeurent en tout temps r&eacute;gies par la
          pr&eacute;sente.
        </p>
        <p>
          Nous avons instaur&eacute; plusieurs mesures de protection physiques,
          administratives et techniques visant &agrave; prot&eacute;ger la
          confidentialit&eacute; et la s&eacute;curit&eacute; des renseignements
          personnels que nous d&eacute;tenons&nbsp;: &agrave; titre
          d&rsquo;exemple, un petit cadenas verrouill&eacute; apparaissant en
          haut de la fen&ecirc;tre du navigateur &agrave; c&ocirc;t&eacute; de
          la barre URL ainsi que l&rsquo;adresse URL commen&ccedil;ant par
          &laquo;https://&raquo; plut&ocirc;t que &laquo;http://&raquo; vous
          indiquent que la page sur laquelle vous &ecirc;tes est
          prot&eacute;g&eacute;e par le protocole SSL. Toutefois, aucune mesure
          de s&eacute;curit&eacute; n&rsquo;est absolue ou enti&egrave;rement
          garantie.
        </p>
        <p>
          SARROS conservera vos renseignements personnels aussi longtemps que
          n&eacute;cessaire aux fins d&eacute;crites dans la pr&eacute;sente
          politique.
        </p>
        <h2 className="h3">
          Mise &agrave; jour concernant les renseignements personnels
        </h2>
        <p>
          Sur demande &eacute;crite et pr&eacute;sentation d&rsquo;une preuve
          d&rsquo;identit&eacute;, vous pouvez acc&eacute;der aux renseignements
          personnels que nous d&eacute;tenons &agrave; votre sujet et,
          s&rsquo;il y a lieu, demander que les rectifications
          n&eacute;cessaires y soient apport&eacute;es, selon ce que la loi
          autorise ou prescrit. Toutefois, pour que les renseignements
          personnels que nous d&eacute;tenons &agrave; votre sujet soient exacts
          et &agrave; jour, veuillez nous informer sans tarder de tout
          changement.
        </p>
        <p>
          Consultez p&eacute;riodiquement cette page pour conna&icirc;tre les
          changements apport&eacute;s &agrave; la pr&eacute;sente politique.
          Nous nous r&eacute;servons le droit de la mettre &agrave; jour
          lorsqu&rsquo;il y a lieu. Si nous modifions la pr&eacute;sente
          politique, nous mettrons la nouvelle version &agrave; votre
          disposition sur le site Web et continuerons de veiller aux respects
          des lois en vigueur. En continuant d&rsquo;utiliser le site Web
          apr&egrave;s l&rsquo;entr&eacute;e en vigueur de la version
          modifi&eacute;e de la pr&eacute;sente politique, vous indiquez que
          vous avez lu et compris la version actuelle de la pr&eacute;sente
          politique et que vous l&rsquo;acceptez.&nbsp;
        </p>

        <h2 className="h3">Coordonn&eacute;es</h2>
        <p>
          Pour poser des questions ou faire des commentaires au sujet de la
          pr&eacute;sente politique ou de vos renseignements personnels, faire
          une demande d&rsquo;acc&egrave;s ou de rectification, exercer tout
          droit applicable, d&eacute;poser une plainte et obtenir de
          l&rsquo;information sur nos politiques, communiquez avec notre
          responsable de la protection des renseignements personnels par
          courriel&nbsp;:&nbsp;
          <a href="mailto:info@equipesarros.ca">info@equipesarros.ca</a>
        </p>
      </article>
    </Layout>
  )
}

export default PoliciesPage
